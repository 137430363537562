import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';


function Cookie(){
    return(
        <Layout>
            <Helmet>
                <title>Cookies | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="OptoSweden har en sekretesspolicy och respekterar din personliga integritet" />
                <meta name="keywords" content="Personuppgifter | sekretesspolicy  | cookie" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="OptoSweden har en sekretesspolicy och respekterar din personliga integritet" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/cookies" />
                <link rel="cannonical" href="https://www.optosweden.se/cookies" />
            </Helmet>


            <section className="border-t border-gray-100 pt-28">
                    <h1 className="text-center title-font font-semibold text-3xl text-blue-custome">Cookies</h1>
            </section>

            <section className="py-20">
                <div className="container mx-auto px-4 xl:px-24">
                    <p className="text-lg text-gray-700">
                        Optosweden.se använder sig av cookies.
                        <br/><br/>
                        En cookie är en liten textfil som webbplatsen 
                        du besöker sparar i din dator. Cookies används 
                        på många webbplatser för att ge besökaren 
                        tillgång till olika funktioner.
                    </p>

                    <h2 className="mt-10 title-font font-semibold text-3xl  text-gray-800">Personuppgifter</h2>
                    <p className="mt-4 text-lg text-gray-700">
                        OptoSweden har en sekretesspolicy och respekterar 
                        din personliga integritet. När du laddar ner eller 
                        fyller i ett formulär ifrån vår hemsida behöver vi 
                        ditt namn, företagsnamn, samt din e-postadress för 
                        att vi ska kunna skicka dig mer information som du 
                        efterfrågar. De upplysningar vi får av dig, kommer 
                        endast att användas för ovanstående interna ändamål. 
                        Vi vidarebefordrar på inga villkor dina uppgifter till 
                        tredje part och under inga omständigheter skulle vi 
                        få för oss att sälja, byta eller låna ut dina uppgifter.
                        <br/><br/>
                        När du laddar ner något ifrån vår hemsida uppger 
                        du dina personuppgifter såsom namn, telefon, 
                        företag och e-post.
                    </p>

                    <h2 className="mt-10 title-font font-semibold text-3xl  text-gray-800">Våra syften med cookies</h2>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span>
                            Samla in och analysera beteendedata baserat på användning 
                            av webbplats och tjänster i syfte att förbättra 
                            användarupplevelsen och även möjliggöra individanpassad 
                            kommunikation och budskap till användaren.
                        </span>
                    </p>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span>
                            Räkna antalet användare och trafik. Genom att 
                            förstå hur webbplatsen används kan vi utveckla 
                            och förbättra användarupplevelsen för våra besökare.
                        </span>
                    </p>
                    <p className="mt-4 text-lg text-gray-700 flex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 mr-4 w-3 h-3 " fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </span>
                        <span> Skicka relevant information och nyhetsbrev.</span>
                    </p>
                </div>
            </section>
        </Layout>
    );
}
export default Cookie;